import * as React from "react";
// import Slider from "react-slick";
import Layout from "../components/layout";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
// import Title from "../components/title"
import Inview from "../components/inview";
const Slingbelt = ({ data }) => {
  const pageData = {
    titleClass: "slingbelt",
    title: "SLING BELT",
    discription:
      "重量物や作業員に優しい吊り具。（例えば、軽金属品、鉄鋼材、機械、石材、鋼菅、プラスチック製品、コンテナ、電気製品等）繊維の柔らかいベルトで重量物を吊り上げることができます。吊荷を傷つけたくない吊り作業、 あらゆる荷役作業に大活躍します。",
    image: 12,
  };
  const seodata = {
    title: "吊り具の定番 スリングベルト [SLING BELT] アヴィエラン株式会社",
    seo: {
      description:
        "重量物や作業員に優しい吊り具。（例えば、軽金属品、鉄鋼材、機械、石材、鋼菅、プラスチック製品、コンテナ、電気製品等）繊維の柔らかいベルトで重量物を吊り上げることができます。吊荷を傷つけたくない吊り作業、 あらゆる荷役作業に大活躍します。",
      image: {
        url: data.datoCmsImage.topimage[12].url,
      },
    },
    slug: "slingbelt",
  };
  // const newsData = data.allDatoCmsNews.nodes
  const imageData = data.datoCmsImage;
  return (
    <Layout pagestyle={pageData} seo={seodata}>
      <div className="pagetitle lashingbelt">
        <div className="fixedtitleimg productimg roundimg">
          <Img
            fluid={imageData.productsImg[5].item}
            alt="吊り具の定番 スリングベルト"
          />
        </div>
        <div className="fixedtexttitle">
          <h1>SLING BELT</h1>
        </div>
      </div>
      <div className="pankz">
        <Link to="/">トップ</Link>
        <Link to="/logisticsequipment/">物流機器事業本部</Link>
        <p>SLING BELT</p>
      </div>
      <div className="l_page_contents">
        <div className="box1">
          <Inview c_name="image">
            <Img
              fluid={data.datoCmsImage.productsImg[16].item}
              alt="吊り具の定番、スリングベルト"
            />
            <Inview c_name="title" tag="h2">
              吊り具の定番、スリングベルト
              <span>A classic hanging tool</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              重量物や作業員に優しい吊り具。（例えば、軽金属品、鉄鋼材、機械、石材、鋼菅、プラスチック製品、コンテナ、電気製品等）繊維の柔らかいベルトで重量物を吊り上げることができます。吊荷を傷つけたくない吊り作業、
              あらゆる荷役作業に大活躍します。
            </Inview>
          </div>
        </div>
        <div className="itemdiscription">
          <div>
            <h3>標準規格品</h3>
            <p>
              CEマーク取得商品
              <br />
              25mm・50mm・75mm・100mm・150mm・200mm・300mm
              <br />
              JIS規格Ⅲ等級
            </p>
          </div>
          <div>
            <h3>抜群の安定性・優れた耐久性 ベルト</h3>
            <p>
              ポリエステル原糸100％使用・2プライ仕様
              <br />
              【ポリエステル繊維の特色】
              <br />
              ・伸び率が低い
              <br />
              ・伸び率にバラツキがない
              <br />
              ・耐久性に優れている
            </p>
          </div>
          <div>
            <h3>補償も万全　生産物賠償責任保険［PL保険］加入商品</h3>
            <p>
              製品の欠陥によって、その製品の消費者その他第三者が生命・身体または財産に被害を被った場合、その製品の製造・販売に関与した事業者が、被害者に対して負うべき法律上の損害賠償責任をＰＬ（製造物責任）といいます。
            </p>
          </div>
          <div>
            <h3>インターナショナルカラー採用</h3>
            <p>ベルト幅によりカラーが定められております。</p>
          </div>
        </div>
        <div className="lineup slingbelt">
          <div>
            <p className="title">
              LINE UP
              <br />
              [ONLINE STORE]
            </p>
          </div>
          <div>
            <a
              href="https://www.slingbelt.com/products/slg25"
              target="_blank"
              rel="noreferrer"
            >
              <Img
                fluid={data.datoCmsImage.productsImg[18].item}
                alt="25mm幅"
              />
              <span>25mm幅</span>
            </a>
          </div>
          <div>
            <a
              href="https://www.slingbelt.com/products/slg50"
              target="_blank"
              rel="noreferrer"
            >
              <Img fluid={data.datoCmsImage.productsImg[5].item} alt="50mm幅" />
              <span>50mm幅</span>
            </a>
          </div>
          <div>
            <a
              href="https://www.slingbelt.com/products/slg75"
              target="_blank"
              rel="noreferrer"
            >
              <Img fluid={data.datoCmsImage.productsImg[6].item} alt="75mm幅" />
              <span>75mm幅</span>
            </a>
          </div>
          <div>
            <a
              href="https://www.slingbelt.com/products/slg100"
              target="_blank"
              rel="noreferrer"
            >
              <Img
                fluid={data.datoCmsImage.productsImg[21].item}
                alt="100mm幅"
              />
              <span>100mm幅</span>
            </a>
          </div>
          <div>
            <a
              href="https://www.slingbelt.com/products/slg150"
              target="_blank"
              rel="noreferrer"
            >
              <Img
                fluid={data.datoCmsImage.productsImg[22].item}
                alt="150mm幅"
              />
              <span>150mm幅</span>
            </a>
          </div>
          <div>
            <a
              href="https://www.slingbelt.com/products/slg200"
              target="_blank"
              rel="noreferrer"
            >
              <Img
                fluid={data.datoCmsImage.productsImg[20].item}
                alt="200mm幅"
              />
              <span>200mm幅</span>
            </a>
          </div>
          <div>
            <a
              href="https://www.slingbelt.com/products/slg300"
              target="_blank"
              rel="noreferrer"
            >
              <Img
                fluid={data.datoCmsImage.productsImg[19].item}
                alt="300mm幅"
              />
              <span>300mm幅</span>
            </a>
          </div>
        </div>
        <div className="itemspec">
          <div>
            <h3>基本使用荷重</h3>
            <div className="spectable">
              <table className="slingspec1">
                <tbody>
                  <tr>
                    <th>　</th>
                    <th>ストレート吊り</th>
                    <th>チョーク吊り</th>
                    <th colSpan="2">2点吊り</th>
                    <th colSpan="2">4点吊り</th>
                  </tr>
                  <tr>
                    <td className="center">吊り方</td>
                    <td className="center">
                      <div className="imgcenter">
                        <Img
                          fluid={data.datoCmsImage.productsImg[12].tebleicon}
                          alt="ストレート吊"
                        />
                      </div>
                    </td>

                    <td className="center">
                      <div className="imgcenter">
                        <Img
                          fluid={data.datoCmsImage.productsImg[13].tebleicon}
                          alt="チョーク吊り"
                        />
                      </div>
                    </td>
                    <td colSpan="2" className="center">
                      <div className="imgcenter">
                        <Img
                          fluid={data.datoCmsImage.productsImg[14].tebleicon}
                          alt="2点吊り"
                        />
                      </div>
                    </td>
                    <td colSpan="2" className="center">
                      <div className="imgcenter">
                        <Img
                          fluid={data.datoCmsImage.productsImg[15].tebleicon}
                          alt="4点吊り"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="center">吊り角度</td>
                    <td> </td>
                    <td> </td>
                    <td className="center">α≦45°</td>
                    <td className="center">45°&lt;α≦90°</td>
                    <td className="center">α≦45°</td>
                    <td className="center">45°&lt;α≦90°</td>
                  </tr>
                  <tr className="center">
                    <td>係数</td>
                    <td>1(基本)</td>
                    <td>0.8</td>
                    <td>1.8</td>
                    <td>1.4</td>
                    <td>3.6</td>
                    <td>2.8</td>
                  </tr>
                  <tr>
                    <td>25&#13212;</td>
                    <td>1.0t</td>
                    <td>0.8t</td>
                    <td>1.8t</td>
                    <td>1.4t</td>
                    <td>3.6t</td>
                    <td>2.8t</td>
                  </tr>
                  <tr>
                    <td>50&#13212;</td>
                    <td>2.0t</td>
                    <td>1.6t</td>
                    <td>3.6t</td>
                    <td>2.8t</td>
                    <td>7.2t</td>
                    <td>5.6t</td>
                  </tr>
                  <tr>
                    <td>75&#13212;</td>
                    <td>3.0t</td>
                    <td>2.4t</td>
                    <td>5.4t</td>
                    <td>4.2t</td>
                    <td>10.8t</td>
                    <td>8.4t</td>
                  </tr>
                  <tr>
                    <td>100&#13212;</td>
                    <td>4.0t</td>
                    <td>3.2t</td>
                    <td>7.2t</td>
                    <td>5.6t</td>
                    <td>14.4t</td>
                    <td>11.2t</td>
                  </tr>
                  <tr>
                    <td>150&#13212;</td>
                    <td>6.0t</td>
                    <td>4.8t</td>
                    <td>10.8t</td>
                    <td>8.4t</td>
                    <td>21.6t</td>
                    <td>16.8t</td>
                  </tr>
                  <tr>
                    <td>200&#13212;</td>
                    <td>8.0t</td>
                    <td>6.4t</td>
                    <td>14.4t</td>
                    <td>11.2t</td>
                    <td>28.8t</td>
                    <td>22.4t</td>
                  </tr>
                  <tr>
                    <td>300&#13212;</td>
                    <td>10.0t</td>
                    <td>8.0t</td>
                    <td>18.0t</td>
                    <td>14.0t</td>
                    <td>36.0t</td>
                    <td>28.0t</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div>
            <h3>商品規格</h3>
            <div className="spectable">
              <div className="kikakuimg">
                <Img
                  fluid={data.datoCmsImage.productsImg[17].kikaku}
                  alt="ストレート吊"
                />
              </div>
              <table>
                <tbody>
                  <tr className="center">
                    <td>ベルト幅</td>
                    <td>A</td>
                    <td>厚さ</td>
                  </tr>
                  <tr>
                    <td>25&#13212;</td>
                    <td>240&#13212;</td>
                    <td>7&#13212;</td>
                  </tr>
                  <tr>
                    <td>50&#13212;</td>
                    <td>280&#13212;</td>
                    <td>7&#13212;</td>
                  </tr>
                  <tr>
                    <td>75&#13212;</td>
                    <td>320&#13212;</td>
                    <td>7&#13212;</td>
                  </tr>
                  <tr>
                    <td>100&#13212;</td>
                    <td>360&#13212;</td>
                    <td>7&#13212;</td>
                  </tr>
                  <tr>
                    <td>150&#13212;</td>
                    <td>460&#13212;</td>
                    <td>7&#13212;</td>
                  </tr>
                  <tr>
                    <td>200&#13212;</td>
                    <td>560&#13212;</td>
                    <td>7&#13212;</td>
                  </tr>
                  <tr>
                    <td>300&#13212;</td>
                    <td>700&#13212;</td>
                    <td>7&#13212;</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Slingbelt;

export const query = graphql`
  query {
    allDatoCmsNews {
      nodes {
        title
        data(formatString: "YYYY/MM/DD")
        meta {
          createdAt(formatString: "YYYY/MM/DD")
          updatedAt(formatString: "YYYY/MM/DD")
        }
      }
    }
    datoCmsImage {
      topimage {
        url
        fluid(
          maxWidth: 1200
          forceBlurhash: false
          imgixParams: { auto: "compress", fit: "crop" }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      productsImg {
        item: fluid(
          maxWidth: 1200
          forceBlurhash: false
          imgixParams: { auto: "compress", fit: "crop", w: "600", h: "600" }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      productsImg {
        tebleicon: fluid(
          maxWidth: 1200
          forceBlurhash: false
          imgixParams: { auto: "compress", fit: "crop", w: "60", h: "60" }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      productsImg {
        kikaku: fluid(
          maxWidth: 1200
          forceBlurhash: false
          imgixParams: { auto: "compress", fit: "crop", w: "800" }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`;
